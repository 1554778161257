@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Libre Baskerville";
  font-size: large;
}

body {
  background-image: url("../public/bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  max-width: 100%;
  overflow-x: hidden;
}