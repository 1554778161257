.header-name {
    text-align: center;
}

.container {
  max-width: 900px !important;
}

.section-button {
  position: relative;
  border-radius: 0 10px 10px 0; /* Adjust the border-radius for the desired semicircle size */
  overflow: hidden;
  background-color: #4682B4;
  color: white;
}

.w-10 {
  width: 10% !important;
}

.w-70 {
  width: 70% !important;
}

.w-33 {
  width: 33% !important;
}

.text-image {
  margin-left: 20px;
  max-width: 40% !important;
  height: 100%;
  border-radius: 5px;
  align-self: center;
}

.h-10 {
  height: 10% !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.centered-text {
  text-align: center;
}

.arrow-list {
  list-style: url("../../public/arrow.svg");
}